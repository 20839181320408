import * as PIXI from 'pixi.js';
import { AppConfig } from '../../../config/AppConfig';
import SpriteCommon from '../common/SpriteCommon';
import ResourceList from '../../../services/ResourceList';
import GameModel, { EGameStates } from '../../../model/GameModel';
import GameScreen from '../../screens/GameScreen';

class PanelControls extends PIXI.Container {
    /**
     * 
     * @param {GameModel} gameModel 
     * @param {GameScreen} gameScreen 
     */
    constructor(gameModel, gameScreen) {
        super();
        this.gameModel = gameModel;
        this.gameScreen = gameScreen;
        this.btnMuteCont = new PIXI.Container();
        this.btnMute = new SpriteCommon(ResourceList.MSC_BTN_MUTE);
        this.btnUnMute = new SpriteCommon(ResourceList.MSC_BTN_UNMUTE);
        this.btnClose = new SpriteCommon(ResourceList.MSC_BTN_CLOSE);

        this.onGameStateUpdated = () => {
            if (this.gameModel.gameState === EGameStates.playing){
                this.btnClose.alpha = 1;
            } else {
                this.btnClose.alpha = 0.9;
            }
        };
        this.onGameStateUpdated();

        this.onResize = (item) => {
            const { gameWidth } = AppConfig.settings;

            this.btnClose.x = gameWidth - this.btnClose.width - 20;
            this.btnMuteCont.x = gameWidth - this.btnMute.width - 20;
        };

        this.onSoundManagerStatusUpdated = (isOn) => {
            if (this.gameScreen.soundManager.isSoundOn) {
                this.btnMute.visible = false;
                this.btnUnMute.visible = true;
            } else {
                this.btnMute.visible = true;
                this.btnUnMute.visible = false;
            }
        }

        this.gameModel.gameStateUpdated.add(this.onGameStateUpdated);
        this.gameScreen.soundManager.statusUpdated.add(this.onSoundManagerStatusUpdated);

        AppConfig.sizeUpdated.add(this.onResize);

        this.onResize();
        this.addButtons();
    }

 

    addButtons() {
        this.addChild(this.btnClose);
        this.addChild(this.btnMuteCont);
        this.btnMuteCont.addChild(this.btnMute);
        this.btnMuteCont.addChild(this.btnUnMute);

        this.btnMuteCont.y = this.btnClose.y + this.btnClose.height + 10;

        this.btnClose.eventMode = "dynamic";
        this.btnMuteCont.eventMode = "dynamic";

        this.btnClose.cursor = "pointer";
        this.btnMuteCont.cursor = "pointer";

        this.btnClose.on('pointerdown', () => {
            this.gameModel.registerCloseInteraction();
            this.gameScreen.countdown.breakCountdown();
        }); 
        
        this.btnMuteCont.on('pointerdown', () => {
            if (!this.gameScreen.soundManager.initialized) {
                this.gameScreen.acitvateSounds();
            } else {
                this.gameScreen.soundManager.isSoundOn = !this.gameScreen.soundManager.isSoundOn;
            }
        });

        this.onSoundManagerStatusUpdated();
    }

}

export default PanelControls