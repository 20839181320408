const GAME_CONFIG = {
    speeds: {
        '30': {speed: 0.3},
        '60': {speed: 0.2},
        '90': {speed: 0.15},
        '120': {speed: 0.12},
        '150': {speed: 0.1},
        'infinity': {speed: 0.1},
    },
    itemsInRow: {
        '60': {row0: 50, row1: 30, row2: 20, row3: 0},
        '120': {row0: 50, row1: 30, row2: 20, row3: 0},
        '180': {row0: 35, row1: 35, row2: 25, row3: 5},
        '240': {row0: 35, row1: 35, row2: 25, row3: 5},
        '300': {row0: 20, row1: 40, row2: 30, row3: 10},
        '330': {row0: 20, row1: 40, row2: 30, row3: 10},
        '360': {row0: 15, row1: 35, row2: 35, row3: 15},
        '390': {row0: 15, row1: 35, row2: 35, row3: 15},
        '420': {row0: 10, row1: 30, row2: 40, row3: 20},
        '450': {row0: 10, row1: 30, row2: 40, row3: 20},
        '480': {row0: 5, row1: 25, row2: 45, row3: 25},
        '510': {row0: 5, row1: 25, row2: 45, row3: 25},
        '540': {row0: 0, row1: 20, row2: 50, row3: 30},
        '570': {row0: 0, row1: 20, row2: 50, row3: 30},
        '600': {row0: 0, row1: 10, row2: 55, row3: 35},
        'infinity': {row0: 0, row1: 10, row2: 55, row3: 35}
    },
    itemKinds: {
        '60': {plus10: 0.62, plus20: 0.35, minusNpoints: 0, minusNseconds: 0, plusNseconds: 0.01, magnet: 0.01, speedUp: 0.01},
        '120': {plus10: 0.62, plus20: 0.35, minusNpoints: 0, minusNseconds: 0, plusNseconds: 0.01, magnet: 0.01, speedUp: 0.01},
        '180': {plus10: 0.62, plus20: 0.35, minusNpoints: 0, minusNseconds: 0, plusNseconds: 0.01, magnet: 0.01, speedUp: 0.01},
        'infinity': {plus10: 0.62, plus20: 0.35, minusNpoints: 0, minusNseconds: 0, plusNseconds: 0.01, magnet: 0.01, speedUp: 0.01},
    },
    steakBonuses: {
        '1000': {multiplier: 'x1'},
        '2000': {multiplier: 'x1.1'},
        '2000': {multiplier: 'x1.2'},
        '4000': {multiplier: 'x1.3'},
        'infinity': {multiplier: 'x5'}
    }

};


export default GAME_CONFIG;