import { AppConfig } from "./AppConfig";

class Language {

    /**
     * 
     * @param {en:string, fr:string} text 
     * @returns string
     */
    static getText(text) {
        return text[AppConfig.lang.toLowerCase()];
    }

}

Language.OUCH = {en:"OUCH!", zh:""};

Language.PRAISE_MAGNET = {en:"INCREDIBLE!", zh:"INCREDIBLE!"};
Language.PRAISE_SPEED = {en:"GREAT!", zh:"GREAT!"};
Language.PRAISE_PLUSTIME = {en:"SUPER!", zh:"SUPER!"};
Language.PRAISE_SEC = {en:"SEC", zh:"SEC"};
/* Language.PRAISE_MAGNET = {en:"INCREDIBLE!", zh:"勁呀!"};
Language.PRAISE_SPEED = {en:"GREAT!", zh:"好叻呀!"};
Language.PRAISE_PLUSTIME = {en:"SUPER!", zh:"好犀利!"};
Language.PRAISE_SEC = {en:"SEC", zh:"秒"}; */




export default Language;