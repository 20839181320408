import EItemsID from "./EItemsID";
import CryptoJS from "crypto-js";
import ItemModel from "./items/ItemModel";

class GameStat {
    constructor() {
        this.itemsHistory = [];
        this.itemsRowHistory = [];
    }

    /**
     * @access package
     */
    resetStats() {
        this.itemsHistory = [];
        this.itemsRowHistory = [];

    }

    /**
     * @access package
     */
    storeСaughtItem(itemKind) {
        this.itemsHistory.push(itemKind);
    }

    /**
     * @access tests
     * @param {Array<ItemModel>} itemsRow
     */
    storeRow(itemsRow) {
        let itemKindLeft = null, itemKindCenter = null, itemKindRight = null;

        for (let i = 0; i < itemsRow.length; i++) {
            const item = itemsRow[i];
            if (item.posInRow === -1) {
                itemKindLeft = item.itemKind.id;
            }
            if (item.posInRow === 0) {
                itemKindCenter = item.itemKind.id;
            }
            if (item.posInRow === 1) {
                itemKindRight = item.itemKind.id;
            }

        }

        this.itemsRowHistory.push([itemKindLeft, itemKindCenter, itemKindRight]);
    }

    /**
     * @access tests
     * @param {Array<ItemModel>} itemsRow
     */
    getProbailitiesStat() {
        // console.log(this.itemsRowHistory);

        const rows = this.itemsRowHistory;

        const counts = {};
        let totalNonNull = 0;
    
        // Count occurrences of each element
        rows.forEach(row => {
            row.forEach(item => {
                if (item !== null) {
                    counts[item] = (counts[item] || 0) + 1;
                    totalNonNull++;
                }
            });
        });
    
        // Normalize to sum to 1
        const percentages = {};
        for (const [key, value] of Object.entries(counts)) {
            percentages[key] = value / totalNonNull;
        }


        console.log(percentages);
    
        return percentages;


    }


    /**
     * @access package
     * @returns {string}
     */
    getItemsHistoryString(gameId) {
        const itemsString = this.itemsHistory.reduce((acc, itemKind) => {
            return acc + (itemKind.numId).toString();
        }, '');

        const itemsImage = CryptoJS.AES.encrypt(JSON.stringify({
            itemsString,
            gameId
        }), 'f0227a92774111eeb9620242ac120002').toString();
        return itemsImage
    }

    /**
    * @access package
    * @returns {{
    *   plus10: number,
    *   plus20: number,
    *   minusNpoints: number,
    *   minusNseconds: number,
    *   plusNseconds: number,
    *   magnet: number,
    *   speedUp: number
    * }}
    */
    getItemsStat() {
        const items = {
            [EItemsID.PLUS10]: 0,
            [EItemsID.PLUS20]: 0,
            [EItemsID.MINUS_N_POINTS]: 0,
            [EItemsID.MINUS_N_SECONDS]: 0,
            [EItemsID.PLUS_N_SECONDS]: 0,
            [EItemsID.MAGNET]: 0,
            [EItemsID.SPEED_UP]: 0,
        };

        this.itemsHistory.forEach(itemKind => {
            if (items.hasOwnProperty(itemKind.id)){
                items[itemKind.id]++;
            } else {
                const error = new Error("UNKNOWN_ITEM", "Unkonwn item error");
                console.log(error.message);
            }

        });
        return items
    }


}

export default GameStat