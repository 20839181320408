import { gsap } from 'gsap';
import * as PIXI from 'pixi.js';
import { AppConfig } from '../../config/AppConfig';
import GameModel, { EGameStates } from '../../model/GameModel';
import AnalyticsEvents from '../../services/AnalyticsEvents';
import AnalyticsService from '../../services/AnalyticsService';
import EMessages from '../../services/EMessages';
import ResourceList from '../../services/ResourceList';
import SoundData from '../../services/SoundData';
import GameScreen from '../screens/GameScreen';
import Utils3D from '../utils/Utils3D';
import SpriteCommon from './common/SpriteCommon';
import StartButton from './StartButton';

class Countdown extends PIXI.Container {
    /**
     * 
     * @param {GameModel} gameModel 
     * @param {GameScreen} gameScreen 
     */
    constructor(gameModel, gameScreen) {
        super();
        const { gameHeight } = AppConfig.settings;
        this.gameModel = gameModel;
        this.gameScreen = gameScreen;
        this.bg = new PIXI.Graphics();
        this.bg.eventMode = "passive";
        this.bgALpha = 0.0;
        this.bg.alpha = this.bgALpha;
        this.showTrainer = true;
        this.showTrainer =  this.gameModel.communictionService.shouldShowTutorial;
        this.gameModel.communictionService.tutorialStatusUpdated.add((shouldShow) => {
            this.showTrainer = shouldShow;
        });

        this.startPressed = false;
        this.pointerGuide = new TreinerPointer();
        this.pointerGuide.visible = false;
        this.pointerGuide.eventMode = "passive";
        this.trainerIntervalID = -1;

        this.trainPassed = {left: false, center: false, righ:false};
        this.trainCurrentPos = -1;
        this.onCartLineUpdated = () => {
            if (this.showTrainer){
                this.checkClicked(this.trainCurrentPos);
            }
            
        };

        

        this.setTrainerByPos = (pos) =>{
            const { gameWidth, gameHeight } = AppConfig.settings;
            this.pointerGuide.iconPos = pos;

            const trgX = Utils3D.getCartXPosByPosInRow(pos) - gameWidth /2;
            const trgY =  gameHeight / 2 - 60; 
            this.pointerGuide.phase = 0;
    
            gsap.timeline()
            .to(this.pointerGuide, { 
                x: trgX, 
                y: trgY, duration: 0.6, 
                ease: "power.out", 
                delay:1,
                onComplete: () => {
                    this.checkClicked(this.trainCurrentPos);
                }})
            .to(this.pointerGuide, { phase: Math.PI * 8, duration: 2, ease: "none" })
    
        }
        this.gameModel.cartLineUpdated.add(this.onCartLineUpdated);

        this.onResize = () => {

            const { gameWidth, gameHeight } = AppConfig.settings;
             const CART_VPOS = 0.7;

            this.redrawBG();
            this.reglesButton.y = gameHeight / 2 - 50;
            this.startButton.y = gameHeight / 2 - 190;
                        
        }
        AppConfig.sizeUpdated.add(this.onResize);
        this.addChild(this.bg);


        this.splashCont = new PIXI.Container();
        this.addChild(this.splashCont);

        
        const lang = AppConfig.lang;
        this.text3 = new SpriteCommon(ResourceList["COUNTDOWN_3_" + lang]);
        this.text2 = new SpriteCommon(ResourceList["COUNTDOWN_2_" + lang]);
        this.text1 = new SpriteCommon(ResourceList["COUNTDOWN_1_" + lang]);
        this.text0 = new SpriteCommon(ResourceList["COUNTDOWN_GO_" + lang]);

        this.startButton = new StartButton();
        this.startButton.y = gameHeight / 2 - 150;
        this.reglesButton = new SpriteCommon(ResourceList["MSC_BTN_REGLES_" + lang]);
        if(AppConfig.autostart === false) {
            this.addChild(this.startButton);
            this.addChild(this.reglesButton);
        }
        
        this.reglesButton.anchor.set(0.5, 0.5);
        this.reglesButton.eventMode = "dynamic";

        this.reglesButton.on('pointerdown', ()=> {
            this.gameModel.showRules = true;
        });
        
        this.startButton.on('startButtonClick', () => {
            this.gameModel.registerCoundownStarted();
            AnalyticsService.logEvent(AnalyticsEvents.CLICK_HOME_START_GAME);
            // this.prepareAndStartCountDown();
        });
        this.gameModel.countdownStarted.add(()=> {
            this.startPressed = true;
            this.prepareAndStartCountDown()
        })

        this.addChild(this.text3, this.text2, this.text1, this.text0);
        this.addChild(this.pointerGuide);

        this.text3.visible = false;
        this.text2.visible = false;
        this.text1.visible = false;
        this.text0.visible = false;

        this.text3.anchor.set(0.5);
        this.text2.anchor.set(0.5);
        this.text1.anchor.set(0.5);
        this.text0.anchor.set(0.5);

        this.text3.position.set(0, 0);
        this.text2.position.set(0, 0);
        this.text1.position.set(0, 0);
        this.text0.position.set(0, 0);

        this.onResize();

  /*       this.spritesheet = ResourceService.getSpriteSheet(ResourceList.EFFECTS);
        this.anim = new AnimatedSprite(this.spritesheet.animations['lightning']);
        this.anim.play();
        this.addChild(this.anim); */
    }

    breakCountdown() {
        // if (this.gameModel.gameState === E
        if (this.gameModel.gameState === EGameStates.ready) {
            if (this.startPressed) {
                gsap.killTweensOf(this.reglesButton);
                gsap.killTweensOf(this.text3);
                gsap.killTweensOf(this.text2);
                gsap.killTweensOf(this.text1);
                gsap.killTweensOf(this.text0);
                gsap.killTweensOf(this.startButton);
                gsap.killTweensOf(this.splashCont);
                gsap.killTweensOf(this.bg);
                gsap.killTweensOf(this.pointerGuide);
                gsap.killTweensOf(this.tutorial_text);
                if (this.countdownTimeline) {
                    this.countdownTimeline.kill();
                    this.countdownTimeline = null;
                    this.text3.visible = false;
                    this.text2.visible = false;
                    this.text1.visible = false;
                    this.text0.visible = false;
                    // this.visible = false;
                }
                this.pointerGuide.visible = false;
        
                this.resetCountDown();
            }
        }
    }

    startTrainer() {

        this.pointerGuide.visible = true;
        this.bg.eventMode = "passive";
        this.pointerGuide.alpha = 0;

        gsap.to(this.splashCont, { alpha: 1, duration: 0.6, onComplete:()=> {
            this.setTrainerByPos(-1);
        }});
        gsap.to(this.bg, { alpha: this.bgALpha, duration: 0.6});
        gsap.to(this.reglesButton, { alpha: 0, duration: 0.6, delay: 0, 
            onComplete:()=>{
            this.reglesButton.visible = false;
        }});
    }

    checkClicked(pos) {
        if (this.showTrainer == false) return
        if (this.gameModel.cartLine === pos) {
            if (pos === 1) {
                this.pointerGuide.visible = false;
                this.startCountdown();
                this.sendTutorialPassed();
            } else {
                this.trainCurrentPos ++;
                this.setTrainerByPos(this.trainCurrentPos);
            }
        }

        if (this.trainerIntervalID > 0) clearTimeout(this.trainerIntervalID);
        // this.trainerIntervalID = setTimeout(this.setTrainerByPos, 5000, this.trainCurrentPos);
    }

    sendTutorialPassed() {
        this.gameModel.sendMessage(EMessages.SND_TUTORIAL_END);
    }
    /**
     * @access public
     */
    resetCountDown() {
        this.visible = true;
        this.startButton.visible = true;
        this.startButton.alpha = 1;
        this.splashCont.alpha = 1;
        this.visible = true;
        this.splashCont.visible = true;
        this.reglesButton.visible = true;
        this.reglesButton.alpha = 1;
        this.bg.visible = true;
        gsap.to(this.bg, { alpha: this.bgALpha, duration: 0.6});
        this.startPressed = false;
        // this.bg.alpha = 1;
    }

    prepareAndStartCountDown() {
        gsap.to(this.bg, { alpha: this.bgALpha, duration: 0.6});
        this.text3.alpha = 0;
        const duration = AppConfig.gameSettings.counDownStepDuration / 2;
        gsap.timeline()
        .to(this.startButton, { alpha: 0, duration: duration, onStart: () => this.text3.visible = true })
        .call(() => {
            if (this.showTrainer) {
                this.startTrainer();
            } else {
                this.startCountdown();
            }
            this.startButton.visible = false;
            this.splashCont.visible = false;               
        }); 

        gsap.to(this.splashCont, { alpha: 0, duration: duration});
    }

    startCountdown() {
        const { counDownStepDuration } = AppConfig.gameSettings;
        const duration = counDownStepDuration;
        this.trainCurrentPos = -1;
        this.showTrainer = false;
        this.pointerGuide.visible = false;
        clearInterval(this.trainerIntervalID);
        gsap.to(this.reglesButton, { alpha: 0, duration: duration, delay: 0, 
            onComplete:()=>{
            this.reglesButton.visible = false;
            this.gameScreen.soundManager.play(SoundData.MSC_COUNDDOWN);
        }});
        this.countdownTimeline = gsap.timeline()
          .to(this.text3, { alpha: 1, duration: duration, delay: duration })
          .to(this.text3, { alpha: 0, duration: duration, delay: duration })
          .to(this.text2, { alpha: 1, duration: duration, 
            onStart: () => {
                this.text2.visible = true;
                this.gameScreen.soundManager.play(SoundData.MSC_COUNDDOWN);
            }})
          .to(this.text2, { alpha: 0, duration: duration, delay: duration })
          .to(this.text1, { alpha: 1, duration: duration, 
            onStart: () => {
                this.text1.visible = true;
                this.gameScreen.soundManager.play(SoundData.MSC_COUNDDOWN);
            }})
          .to(this.text1, { alpha: 0, duration: duration, delay: duration })
          .to(this.text0, { alpha: 1, duration: duration, 
            onStart: () => {
                this.text0.visible = true;
                this.gameScreen.soundManager.play(SoundData.MSC_COUNDDOWN_GO);
            }})
          .to(this.text0, { alpha: 0, duration: duration, delay: duration })
          .call(() => {
            // this.emit('countdownComplete');
            this.gameModel.registerCoundownComplete();
            this.text3.visible = false;
            this.text2.visible = false;
            this.text1.visible = false;
            this.text0.visible = false;
            this.visible = false;
            this.gameScreen.soundManager.play(SoundData.MSC_START);
        }); 
    }

    redrawBG(){
        const { gameWidth, gameHeight } = AppConfig.settings;
        this.bg.clear();
        this.bg.beginFill(0x000000);
        this.bg.drawRect( - gameWidth / 2, - gameHeight / 2,  gameWidth, gameHeight);
        this.bg.endFill();
    }   
}

class TreinerPointer extends PIXI.Sprite {
    constructor() {
        const { gameWidth, gameHeight } = AppConfig.settings;
        super(PIXI.Texture.EMPTY);
        this.mouseIconLeft = new SpriteCommon(ResourceList["MSC_CART_CLICKER_LEFT_" + AppConfig.lang]);
        this.mouseIconCenter = new SpriteCommon(ResourceList["MSC_CART_CLICKER_CENTER_" + AppConfig.lang]);
        this.mouseIconRight = new SpriteCommon(ResourceList["MSC_CART_CLICKER_RIGHT_" + AppConfig.lang]);
        this.addChild(this.mouseIconLeft);
        this.addChild(this.mouseIconCenter);
        this.addChild(this.mouseIconRight);
        // this.mouseIconLeft.alpha = 1;
        // this.mouseIconCenter.alpha = 0;
        // this.mouseIconRight.alpha = 0; 

        this.mouseIconLeft.eventMode = "passive";
        this.mouseIconCenter.eventMode = "passive";
        this.mouseIconRight.eventMode = "passive";

        this.mouseIconLeft.anchor.set(0.5, 0.5);
        this.mouseIconCenter.anchor.set(0.5, 0.5);
        this.mouseIconRight.anchor.set(0.5, 0.5);

        this._iconPos = 0;
        this.iconPos = -1;

        this.eventMode = "passive";
        this._phase = Math.PI / 2;
        this.anchor.set(0.5, 0.5);
        this.y = 100;       
    }

    get phase() {return this._phase};
    set phase (value) {
        if (this._phase === value) return
        this._phase = value;
        this.alpha = Math.abs(Math.cos(value))
    }

    get iconPos() {return this._iconPos};
    set iconPos (value) {
        if (this._iconPos === value) return
        this._iconPos = value;
        const duration = 0.7;
        const delay = 0.75;
        if (value === -1) {
            
            this.mouseIconLeft.alpha = 0;
            gsap.to(this.mouseIconLeft, { alpha: 1, duration: duration, delay: delay });
            gsap.to(this.mouseIconCenter, { alpha: 0, duration: duration, delay: delay });
            gsap.to(this.mouseIconRight, { alpha: 0, duration: duration, delay: delay });
        }
        if (value === 0) {
            this.mouseIconCenter.alpha = 0;
            gsap.to(this.mouseIconLeft, { alpha: 0, duration: duration, delay: delay});
            gsap.to(this.mouseIconCenter, { alpha: 1, duration: duration, delay: delay});
            gsap.to(this.mouseIconRight, { alpha: 0, duration: duration, delay: delay});
        }
        if (value === 1) {
            this.mouseIconRight.alpha = 0;
            gsap.to(this.mouseIconLeft, { alpha: 0, duration: duration, delay: delay});
            gsap.to(this.mouseIconCenter, { alpha: 0, duration: duration, delay: delay});
            gsap.to(this.mouseIconRight, { alpha: 1, duration: duration, delay: delay});
        }
    } 


}




export default Countdown