import ResourceList from "../../../services/ResourceList";
import InfoBox from "./InfoBox";
import InfoBoxWithIcon from "./InfoBoxWithIcon";

class ScoreInfo extends InfoBoxWithIcon {
    constructor() {
        super(ResourceList.MSC_COIN);
    }

    /**
     * @access public
     */
    resizeLabel() {
        super.resizeLabel();
        const disckOverMargin = this.getDiskOverMargin(this.iconHolder.height / 2, this.bg.height);
        

        const margin = this.iconHolder.x;
        if (this.label) this.label.x = 3 * margin;
        this.setComponentWidth(this.label.x + this.label.width  + 10);
    }

    /**
     * @access public
     * @param {number} seconds 
     */
    setScores(value){
        this.label.text = value;
        // this.labelShadow.text = value + "/";
        this.resizeLabel();

    }
}

export default ScoreInfo