class EMessages {}
EMessages.SND_ALL_LOADED    = 'all-loaded';
EMessages.SND_TUTORIAL_END  = 'tutorial-end';
EMessages.SND_GAME_START    = 'game-start';
EMessages.SND_GLOSE         = 'close';
EMessages.SND_GLOSE_ON_DONE = 'close-on-done';
EMessages.SND_GAME_END      = 'game-end';

EMessages.SND_EXT_SECOND_PAGE_INIT  = 'webEvent_game_second_page_init';
EMessages.SND_EXT_WEBGAME_CLOSE     = 'webEvent_game_close';
EMessages.SND_EXT_CLICK_BANNER      = 'webEvent_game_click_banner';


EMessages.RCV_GAME_INFO     = "game-info";


EMessages.RCV_GAME_RGISTERD             = "game-registered";
EMessages.RCV_SHOW_PRELOAD_ANIMATION    = "show-preloader-animation";
EMessages.RCV_CLOSE_REFUSE              = "closed-refuse";
EMessages.RCV_USER_STOP_GAME            = "user-stop-game";
EMessages.RCV_PLAY_AGAIN                = "play-again";
EMessages.RCV_SHOW_RULES                = "show-rules";

export default EMessages;