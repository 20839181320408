
import { MiniSignal } from "mini-signals";
import Utils3D from "../view/utils/Utils3D";
import Point3D from "../model/pseudo3ds/Point3D";
import EGameNames from "./EGameNames";
import { Utils } from "../view/utils/Utils";

class AppConfig {

    
    // static gameID = EGameNames.CINEPLEX_G1;
    static gameID = window.gameMode;
    static lang = window.lang.toUpperCase();
    static sizeUpdated = new MiniSignal();
    static cameraUpdated = new MiniSignal();
    static isDebug = window.isDebug;
    static hidelogs = window.hidelogs;
    static autostart = window.autostart;


    /** 
     * @readonly
     */
    static base = {
        WIDTH: 375,
        HEIGHT: 730,
        MAX_WIDTH:1440,
        MAX_HEIGHT:970,
        MAX_CONVEYOR_HEIGHT: 800,
        MIN_CONVEYOR_HEIGHT: 450,
        DOOR_MAX_SCALE: 1.3,
        DOOR_MIN_SCALE: 0.6,
        Z_CART_POSITION: 10,
        Z_ITEM_DISAPPEAR: -20,
        CONVEYOR_3D_WIDTH: 7
    }


    /** 
     * @readonly
     */
    static settings = {
        gameSkin:1,
        gameWidth: AppConfig.base.WIDTH,
        gameHeight: AppConfig.base.HEIGHT,
        gameMinWidth: AppConfig.base.WIDTH,
        xResponsiveFactor: 1,
        yResponsiveFactor: 1,
        centerX: AppConfig.baseWidth / 2,
        useShadowsForProducts: false,
        soundsByDefault: true,
        keyPadAlpha:0,
    }


    static updateSize (w, h) {
        const { MIN_CONVEYOR_HEIGHT, MAX_CONVEYOR_HEIGHT } = AppConfig.base;
        AppConfig.settings.gameWidth = w;
        AppConfig.settings.gameHeight = h;
        AppConfig.settings.xResponsiveFactor = w / AppConfig.base.WIDTH;
        AppConfig.settings.yResponsiveFactor = h / AppConfig.base.HEIGHT;

/*         const conveyorRealWidth = Utils.clamp(w / 2, 250, 600);
        AppConfig.dynamicHelpers.conveyorRealWidth = conveyorRealWidth;
        const widePhase = Utils.inverseLerp(w / 2, 250,  400);
        AppConfig.dynamicHelpers.widePhase = widePhase;
        const conveyorWidth3D = Utils3D.getConveyorWidth3DByRealWidth(conveyorRealWidth);
        AppConfig.settings3D.conveyorWidth = conveyorWidth3D; */


        AppConfig.settings3D.zCartPosition = Utils3D.getCart3DZByPoint2DY(h - 50);
        AppConfig.settings3D.conveyorWidth  = AppConfig.base.CONVEYOR_3D_WIDTH;
        let conveyorHeight = h * 0.75;
        if (conveyorHeight < MIN_CONVEYOR_HEIGHT) conveyorHeight = MIN_CONVEYOR_HEIGHT;
        if (conveyorHeight > MAX_CONVEYOR_HEIGHT) conveyorHeight = MAX_CONVEYOR_HEIGHT;
        const conveyorVPhase = Utils.inverseLerp(conveyorHeight, MIN_CONVEYOR_HEIGHT, MAX_CONVEYOR_HEIGHT);
        // AppConfig.settings3D.itemsExtraScale = Utils.lerp(conveyorVPhase, 0.4, 0.8);
        AppConfig.settings3D.itemsExtraScale = Utils.lerp(conveyorVPhase, 0.6, 0.9);
        const centerY = h - conveyorHeight;
        AppConfig.settings3D.horyzontPos = centerY / h;
        AppConfig.settings3D.conveyorCorners = Utils3D.updateConveyorCorners();
        AppConfig.sizeUpdated.dispatch();
        AppConfig.updateCamera();       
    }

    static updateSizeNew (w, h) {
        const { MIN_CONVEYOR_HEIGHT, MAX_CONVEYOR_HEIGHT } = AppConfig.base;
        
        AppConfig.settings.gameWidth = w;
        AppConfig.settings.gameHeight = h;
        AppConfig.settings.xResponsiveFactor = w / AppConfig.base.WIDTH;
        AppConfig.settings.yResponsiveFactor = h / AppConfig.base.HEIGHT;

        const conveyorRealWidth = Utils.clamp(w / 2, 250, 600);
        AppConfig.dynamicHelpers.conveyorRealWidth = conveyorRealWidth;
        const widePhase = Utils.inverseLerp(w / 2, 250,  400);
        AppConfig.dynamicHelpers.widePhase = widePhase;
        const conveyorWidth3D = Utils3D.getConveyorWidth3DByRealWidth(conveyorRealWidth);
        AppConfig.settings3D.conveyorWidth = conveyorWidth3D;
        AppConfig.settings3D.zCartPosition = Utils3D.getCart3DZByPoint2DY(h - 30);

        let conveyorHeight = h * 0.75;
        if (conveyorHeight < MIN_CONVEYOR_HEIGHT) conveyorHeight = MIN_CONVEYOR_HEIGHT;
        if (conveyorHeight > MAX_CONVEYOR_HEIGHT) conveyorHeight = MAX_CONVEYOR_HEIGHT;
        const conveyorVPhase = Utils.inverseLerp(conveyorHeight, MIN_CONVEYOR_HEIGHT, MAX_CONVEYOR_HEIGHT);
        

        
        const centerY = h - conveyorHeight;
        AppConfig.settings3D.horyzontPos = centerY / h;
        AppConfig.settings3D.conveyorCorners = Utils3D.updateConveyorCorners();
        // AppConfig.settings3D.itemsExtraScale = Utils.lerp(conveyorVPhase, 16.5, 16.8);
        // AppConfig.settings3D.itemsExtraScale = 28;
    
        AppConfig.sizeUpdated.dispatch();
        AppConfig.updateCamera();     

    }

    static updateCamera (offsetX) {
        if (offsetX != undefined) AppConfig.settings3D.cameraOffsetX = offsetX;
        AppConfig.settings.centerX = AppConfig.settings.gameWidth / 2 + offsetX / 20;
        AppConfig.cameraUpdated.dispatch();
    }

    /**
     * We need it because too many visual things from different structure depend on the door now
     */

    static dynamicHelpers = {
        doorTop: 0,
        doorBottom: 0,
        doorPhase: 0,
        widePhase: 0,
        conveyorBottomCorner:0,
        conveyorRealWidth:0,
        cartHeight:100,

    }

    /** 
     * @readonly
     */
    static settings3D = {
        itemsExtraScale: 0.8,
        focalLength: 10,
        scaleZoom: 26,
        horyzontPos: 0.2,
        floorLineFrom3DCenter: 250,
        floorLineFromTop3DCornder: 50,
        animationSped: 1 * 5,
        worldSize: 1000,
        conveyorY: 12,
        conveyorWidth: AppConfig.base.WIDTH,
        productLineOnConveyor: 0.9,
        zPseudo3DBound: -20,
        zCartPosition: 10,
        zDeep:70,
        zAppeared: 40,
        cameraOffsetX: 0,
        showConeyor3Dhelper: false
    };

    /** 
     * @readonly
     */
    static gameSettings = {
        levelMaxScores: 500,
        cartWidth: 50,
        newItemDelay: 1 * 1000,
        magnetItemsCount: 100,
        magnetMaxDuration: 3000, //we double calcuatin the livetime of mugnet by timeout or items count
        speedUpDuration: 3000,
        timeMax: 180,
        initialTimeLeft: 20,
        initialSpeed: 0.35,
        counDownStepDuration:0.5,
        maxEmptyRows: 3,
        maxScores:1000
    };

    /** 
     * @readonly
     */
    static animationSettings = {
        itemJumpDuration: 0.3,
        itemDropDuration: 0.6,
        displayItemsInCart: 50
    };

    /** 
     * @readonly
     */
    static particleSettings = {
    };

    /** 
     * @readonly
     */
    static debugSettings = {
        emulateBigDataItems: true
    };

    static isMobileDevice() {
        const isMobile = /Mobile|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        console.log("agent:" + navigator.userAgent + "; isMobile:" + isMobile);
        return isMobile
    }
}

export { AppConfig };