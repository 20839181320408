import * as PIXI from 'pixi.js';
import { AppConfig } from "../../../config/AppConfig";
import ResourceList from "../../../services/ResourceList";
import BackgroundAbstract from "./BackgroundAbstract";
import FixiedTilingSprite from './FixiedTilingSprite';
import SpriteCommon from '../common/SpriteCommon';
import Background3D from './Background3D';
import { Utils } from '../../utils/Utils';
import CloudFlying from './CloudFlying';
import Fireworks from '../effects/Fireworks';
import DebugService from '../../../services/DebugService';
import ObjectFlying from './ObjectFlying';

class Background extends BackgroundAbstract{
    constructor() {
        super();
    }

     /**
     * @access public
     */
     createElements() {
        
        this.wall = new FixiedTilingSprite(ResourceList.BG_1_WALL, 512, 343);
        this.floor = new FixiedTilingSprite(ResourceList.BG_1_FLOOR, 800, 600);
        this.door = new SpriteCommon(ResourceList.BG_1_DOOR);
        // this.door2 = new SpriteCommon(ResourceList.BG_1_DOOR);
        // this.door.tint = 0xff0000;

        this.floor.visible = false;

        super.createElements();

        const { gameWidth, gameHeight } = AppConfig.settings;

        this.objectLeft1 = new SpriteCommon(ResourceList.BG_1_OBJ_LEFT_1);
        this.objectRight1 = new SpriteCommon(ResourceList.BG_1_OBJ_RIGHT_1);

        this.objectLeft1.anchor.set(1, 0);
        this.objectRight1.anchor.set(0, 0);

        this.objectLeft1.scale.set(0.5);
        this.objectRight1.scale.set(0.5);

        this.cloud1 = new CloudFlying(ResourceList.BG_1_OBJ_CLOUD_1);
        this.cloud2 = new CloudFlying(ResourceList.BG_1_OBJ_CLOUD_2);
        this.cloud3 = new CloudFlying(ResourceList.BG_1_OBJ_CLOUD_3);
        this.cloud4 = new CloudFlying(ResourceList.BG_1_OBJ_CLOUD_4);
        // this.cloud5 = new CloudFlying(ResourceList.BG_1_OBJ_CLOUD_5);
        // this.cloud6 = new CloudFlying(ResourceList.BG_1_OBJ_CLOUD_6);

        

        this.addChild(this.objectLeft1);
        this.addChild(this.objectRight1);
        this.addChild(this.cloud1);
        this.addChild(this.cloud2);
        this.addChild(this.cloud3);
        this.addChild(this.cloud4);
        this.addChild(this.cloud4);
        // this.addChild(this.cloud5);
        // this.addChild(this.cloud6);
        

        const c1 = this.cloud1;
        c1.speedX = 0.001;
        c1.speedY = 0.062;
        c1.rangeX = 500;
        c1.rangeY = 2;


        const c2 = this.cloud2;
        c2.speedX = -0.0013;
        c2.speedY = 0.021;
        c2.rangeX = 500;
        c2.rangeY = 10;
        c2.t = 4000;

        const c3 = this.cloud3;
        c3.speedX = 0.001;
        c3.speedY = 0.013;
        c3.rangeX = 500;
        c3.rangeY = 20;
        c3.scale.set(2, 0.2);

        const c4 = this.cloud4;
        c4.speedX = 0.001;
        c4.speedY = 0.0134;
        c4.rangeX = 200;
        c4.rangeY = 10;
        c4.scale.set(1, 0.6);

/*         const c5 = this.cloud5;
        c5.speedX = 0.001;
        c5.speedY = 0.1;
        c5.rangeX = 500;
        c5.rangeY = 2;

        const c6 = this.cloud6;
        c6.speedX = 0.001;
        c6.speedY = 0.1;
        c6.rangeX = 500;
        c6.rangeY = 2; */

        this.createElementsAfter();

        // this.addFlyingObjects();

    
    }

    addFlyingObjects() {
        //Assets are disabled
        this.element1 = new ObjectFlying(ResourceList.BG_1_ELEMENT_1);
        this.element2 = new ObjectFlying(ResourceList.BG_1_ELEMENT_2, this.element1, 10, -20);
        this.element3 = new ObjectFlying(ResourceList.BG_1_ELEMENT_3, this.element2, 10, -20);
        this.element4 = new ObjectFlying(ResourceList.BG_1_ELEMENT_4, this.element3, 10, -20);
        this.element5 = new ObjectFlying(ResourceList.BG_1_ELEMENT_5, this.element4, 10, -20);
        this.element6 = new ObjectFlying(ResourceList.BG_1_ELEMENT_6, this.element5, 10, -20);
        this.element7 = new ObjectFlying(ResourceList.BG_1_ELEMENT_7, this.element6, 10, -20);
        this.element8 = new ObjectFlying(ResourceList.BG_1_ELEMENT_8, this.element7, 10, -20);

        
        this.objectLeft1.addChild(this.element8);
        this.objectLeft1.addChild(this.element7);
        this.objectLeft1.addChild(this.element6);
        this.objectLeft1.addChild(this.element5);
        this.objectLeft1.addChild(this.element4);
        this.objectLeft1.addChild(this.element3);
        this.objectLeft1.addChild(this.element2);
        this.objectLeft1.addChild(this.element1);

        for (let i = 1; i < 9; i++) {

            const obj = this["element" + i];
   
            // obj.scale.set(0.5);
            obj.axisX = 150 - Math.random() * 300 - 200;
            obj.axisY = 150 - Math.random() * 300;

            obj.shiftX = 150 - Math.random() * 300 - 500;
            obj.shiftY = 150 - Math.random() * 300;

            obj.speedX = 0.001 * Math.random() * 0.5;
            obj.speedY = 0.1 * Math.random() * 0.5;
            obj.t = Math.random() * 300;

        }
    }


    /**
     * @access public
     */
    onResize() {
        super.onResize();
        const { DOOR_MAX_SCALE, DOOR_MIN_SCALE } = AppConfig.base;
        const { gameWidth, gameHeight } = AppConfig.settings;
        const { horyzontPos } = AppConfig.settings3D;
        const { floorLineFrom3DCenter } = AppConfig.settings3D;
        const centerY = gameHeight * horyzontPos;
        const floorLine = centerY + floorLineFrom3DCenter;;
        
        const centerX = gameWidth / 2;
        const conveyorWidth = 100 * this.door.scale.x;

        const doorPhase = Utils.inverseLerp(this.door.scale.x, DOOR_MIN_SCALE, DOOR_MAX_SCALE);
        const objDisplace = Utils.lerp(doorPhase, 50, 150);

        // const objDisplace = -5;

        const conveyorLineY = gameHeight - this.conveyor.y;
        const conveyorLineXFromY = (conveyorLineY * (238 / 780) + 88) * this.conveyor.scale.x;

        this.objectLeft1.x = centerX - conveyorWidth / 2 - objDisplace;
        this.objectRight1.x = centerX + conveyorWidth / 2 + objDisplace - 20;

        this.objectLeft1.y = floorLine - 235;
        this.objectRight1.y = this.objectLeft1.y;

        
        const objSkew = Utils.lerp(doorPhase, 0.077, -0.08);
        this.objectLeft1.skew.x = objSkew;
        this.objectRight1.skew.x = -objSkew;

        this.cloud1.axisX = gameWidth / 2 - conveyorWidth / 2;
        this.cloud1.axisY = gameHeight - gameHeight / 8;

        this.cloud2.axisX = gameWidth / 2 + conveyorWidth / 2;
        this.cloud2.axisY = gameHeight -  gameHeight / 8;;

        
        this.cloud3.axisX = - this.cloud3.rangeX / 2;
        this.cloud3.axisY = gameHeight * 0.2;
        
        this.cloud4.axisX = gameWidth - this.cloud3.rangeX / 2;
        this.cloud4.axisY = gameHeight * 0.3;

        DebugService.logFPS(this.door.scale.x);

 






    }

    /**
     * @access public
     */
    animate(diff) {
        this.cloud1.animate(diff);
        this.cloud2.animate(diff);
        this.cloud3.animate(diff);
        this.cloud4.animate(diff);
        // this.cloud5.animate(diff);
        // this.cloud6.animate(diff);

/*         this.element1.animate(diff);
        this.element2.animate(diff);
        this.element3.animate(diff);
        this.element4.animate(diff);
        this.element5.animate(diff);
        this.element6.animate(diff);
        this.element7.animate(diff);
        this.element8.animate(diff); */
    }

}

export default Background;