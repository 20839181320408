import * as PIXI from 'pixi.js';
import SpriteCommon from '../common/SpriteCommon';
import ResourceList from '../../../services/ResourceList';
import { AppConfig } from '../../../config/AppConfig';
import { Utils } from '../../utils/Utils';
import Background3D from './Background3D';
import FullScreenGradient from '../common/FullScreenGradient';

class BackgroundAbstract extends PIXI.Container {
    constructor() {
        super();
        this.createElements();
        this.helperRectangle();
        this.onResize();
        this.showHideLights(false);



        this.cameraUpdated = () => {
            this.onResize();
        }
        AppConfig.cameraUpdated.add(this.cameraUpdated);

        
    }

    /**
     * @access public
     */
    createElements() {
        const gradientStr = "#BFF8FF -10.85%, #C3F8F8 1.46%, #CFFAE5 38.4%, #DEFCCA 50.71%, #E8FAA4 75.34%, #EEF98D 99.96%, #F1F985 112.27%"

        // this.gradient = new FullScreenGradient(['#E0FDF1', '#DDFBFA', '#D7F6FD','#D0E1FC', "#D3E8FC", "#D0E1FC", "#C4DAFC", "#BBD6FB", "#ADD1F2", "#9AC9E4", "#8FC3DF", "#97C7E4", "#97CAE4", "#84B8E0"], 0.5, this);
        this.gradient = new FullScreenGradient(gradientStr, 0.5, this);

        // background: linear-gradient(180deg, #E0FDF1 0%, #DDFBFA 6.97%, #D7F6FD 15.22%, #D4EDFC 21.01%, #D3E8FC 22.36%, #D0E1FC 24.7%, #C4DAFC 28.28%, #BBD6FB 32.01%, #ADD1F2 37.51%, #9AC9E4 44.05%, #8FC3DF 50.19%, #97C7E4 56.25%, #97CAE4 60.75%, #84B8E0 100%);

        this.addChild(this.gradient);
        
        if (this.wall && this.floor) {
            // this.addChild(this.wall);
            // this.addChild(this.floor);

            this.conveyor = new SpriteCommon(ResourceList.CONVEYOR);
            // this.conveyor = new PIXI.NineSlicePlane(ResourceService.getTexture(ResourceList.CONVEYOR), 255, 255, 0, 0);
            //TODO Make NineSlice for conveyor for avoiding mismatching shelves and conveyor lines

            
            this.conveyor.anchor.set(0.5, 0);
            this.conveyor.height = 780;
            this.addChild(this.conveyor);

            this.door.anchor.set(0.5, 1);
            this.addChild(this.door);
    
        }

        this.conveyorLightLeft = new SpriteCommon(ResourceList.CONVEYOR_LIGHT_LEFT);
        this.conveyorLightRight = new SpriteCommon(ResourceList.CONVEYOR_LIGHT_LEFT);
        this.conveyorLightLeft.anchor.set(0, 0);
        this.conveyorLightRight.anchor.set(0, 0);
        this.conveyorLightRight.scale.set(-1, 1);
        this.addChild(this.conveyorLightLeft);
        this.addChild(this.conveyorLightRight);
        this.conveyorLightLeft.blendMode = PIXI.BLEND_MODES.SCREEN;
        this.conveyorLightRight.blendMode = PIXI.BLEND_MODES.SCREEN;
    }

    createElementsAfter() {
        if (AppConfig.settings3D.showConeyor3Dhelper){
            this.bg3D = new Background3D();
            this.addChild(this.bg3D);   
        }
    }


    /**
     * @access public
     */
    onResize() {
        const { DOOR_MAX_SCALE, DOOR_MIN_SCALE } = AppConfig.base;
        const { gameWidth, gameHeight } = AppConfig.settings;
        const { floorLineFrom3DCenter, floorLineFromTop3DCornder } = AppConfig.settings3D;
        const centerX = gameWidth / 2;
        const { horyzontPos } = AppConfig.settings3D;
        const { topLeft, topRight, bottomRight, bottomLeft, lampFrontLeft, lampFrontRIght, lampBacktLeft, lampBacktRIght } = AppConfig.settings3D.conveyorCorners;

        const centerY = gameHeight * horyzontPos;
        // const floorLine = topLeft.y + floorLineFromTop3DCornder;;
        const floorLine = topLeft.y + floorLineFromTop3DCornder;
        
        this.wall.y = 0;
        this.wall.x = 0;
        this.wall.onResize(gameWidth, floorLine, "center", "bottom");
        this.floor.onResize(gameWidth, gameHeight, "center", "top");
        this.floor.y = floorLine;
        this.door.x = centerX;

        // this.helperRect.x = centerX;
        // this.helperRect.y = centerY
        
        this.door.height = floorLine - 10;
        let doorScale = this.door.scale.y;
        if (doorScale < DOOR_MIN_SCALE) doorScale = DOOR_MIN_SCALE;
        if (doorScale > DOOR_MAX_SCALE) doorScale = DOOR_MAX_SCALE;
        // this.door.scale.set(doorScale * 1.2, doorScale);
        this.door.scale.set(doorScale * 1, doorScale);
        this.door.y = floorLine;

        this.conveyor.alpha = 1;
        this.conveyor.x = centerX;
        this.conveyor.y = floorLine - this.door.height * 0.4;

        // this.conveyor.scale.set(1);
        const doorPhase = Utils.inverseLerp(this.door.scale.x, DOOR_MIN_SCALE, DOOR_MAX_SCALE);
        this.conveyor.width = Utils.lerp(doorPhase, 380, 630);

        AppConfig.dynamicHelpers.doorBottom = floorLine;
        AppConfig.dynamicHelpers.doorTop = floorLine - this.door.height;
        AppConfig.dynamicHelpers.doorPhase = doorPhase;

        const conveyorLineY = gameHeight - this.conveyor.y;
        const conveyorLineXFromY = (conveyorLineY * (238 / 780) + 88) * this.conveyor.scale.x;
        // const conveyorLineXFromY = conveyorLineY;

        AppConfig.dynamicHelpers.conveyorBottomCorner = conveyorLineXFromY;
        // console.log(conveyorLineXFromY);

        this.conveyorLightLeft.y = this.conveyor.y;
        const displaceX = 260;
        this.conveyorLightLeft.x =  Utils.lerp(doorPhase, centerX - displaceX, centerX - displaceX - 45);
        this.conveyorLightRight.y = this.conveyor.y;
        this.conveyorLightRight.x = Utils.lerp(doorPhase, centerX + displaceX, centerX +  displaceX + 45);
        
        const objSkew = Utils.lerp(doorPhase, 0.05, -0.08);
        this.conveyorLightLeft.skew.x = objSkew;
        this.conveyorLightRight.skew.x = -objSkew;

        if (AppConfig.settings3D.showConeyor3Dhelper) this.bg3D.redraw();
    }

    /**
     * @access public
     */
    showHideLights(show) {
        this.conveyorLightLeft.visible = show ? true : false;
        this.conveyorLightRight.visible = show ? true : false;
        
    }

    helperRectangle() {
        return
        
        // this.helperRect = new SpriteCommon(ResourceList.BG_1_HEART);
        this.helperRect = new SpriteCommon();
        this.helperRect.alpha = 0.5;
        // this.helperRect.anchor.set(1, 1);
        const rect = new PIXI.Graphics();
        rect.beginFill(0xFFFF00);
        // graphics.lineStyle(5, 0xFF0000);
        rect.drawRect(0, 0, 5, 5);
        this.helperRect.addChild(rect);
        return
        this.addChild(this.helperRect);
    }

}

export default BackgroundAbstract;