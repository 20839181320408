{
  "name": "disney-stamps",
  "version": "5.2.21",
  "description": "Disney 7/11 Stamps",
  "type": "module",
  "scripts": {
    "clear": "rm -rf dist/* && rm -rf .parcel-cache/*",
    "start1": "PORT=8080 parcel src/index.html --https --open 'https://localhost:8080/?gamemode=1'",
    "start2": "PORT=8080 parcel src/index.html --https --open 'https://localhost:8080/?gamemode=2'",
    "start3": "PORT=8080 parcel src/index.html --https --open 'https://localhost:8080/?gamemode=3'",
    "start4": "PORT=8080 parcel src/index.html --https --open 'https://localhost:8080/?gamemode=4'",
    "start": "PORT=8086 parcel src/index.html",
    "build": "node update_version.js && npm run clear && parcel build src/index.html --public-url ./ && node process_files.js && npm run zip",
    "zip": "VERSION=$(node -pe \"require('./package.json').version\") && cd dist && zip -r ../../disney-stamps_$VERSION.zip ./ && cd ..",
    "incr": "node update_version.js",
    "publish": "npm run build && bash ../publish_game.sh disneyStampsChances"
  },
  "author": "ushan",
  "license": "MIT",
  "dependencies": {
    "@pixi/filter-drop-shadow": "^5.2.0",
    "@pixi/filter-zoom-blur": "^5.1.1",
    "crypto-js": "^4.2.0",
    "firebase": "^10.11.0",
    "gsap": "^3.6.1",
    "howler": "^2.2.4",
    "mini-signals": "^2.0.0",
    "pixi.js": "^7.3.2"
  },
  "devDependencies": {
    "@parcel/config-default": "2.9.2",
    "cheerio": "^1.0.0",
    "parcel": "^2.10.1",
    "parcel-plugin-static-files-copy": "file:../..",
    "parcel-reporter-static-files-copy": "^1.5.3",
    "process": "^0.11.10",
    "punycode": "^1.4.1",
    "querystring-es3": "^0.2.1",
    "ts-loader": "^8.0.11",
    "typescript": "^4.0.5"
  },
  "staticFiles": {
    "staticPath": "src/assets",
    "staticOutPath": "assets",
    "watcherGlob": "**"
  },
  "keywords": []
}